<template>
  <div class="reg-container -scrollable text">
      登记表单
      
      
      
      
      
      <sea-button @action="joinRoom" class="custombtn">进入视频处理</sea-button>
  </div>
</template>

<style lang="scss">
.reg-container {

}
</style>

<script>
import SeaButton from "../ui/sea-button"
import { Logger } from "../lib/logger"
const log = Logger("app:app-reg")

export default {
  name: "app-reg",
  components: {SeaButton},
  data() {
    return {
     
    }
  },
  created() {
    //console.log('app-reg created')
  },
  mounted() {
    //console.log('app-reg mounted')
  },
  beforeDestroy() {
    //console.log('app-reg beforeDestroy')
  },
  methods: {
    joinRoom() {
        location.replace(location.href.replace('/?room/', '/room/'))
    },
  },
}
</script>
